var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    ref: "leftFrame",
    attrs: {
      src: _vm.scratchIde,
      frameborder: "1",
      allowfullscreen: "true",
      width: "100%",
      height: "99%",
      seamless: "",
      sandbox:
        "allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }