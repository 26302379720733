<template>
    <iframe
      ref="leftFrame"
      :src="scratchIde"
      frameborder="1"
      allowfullscreen="true"
      width="100%"
      height="99%"
      seamless
      sandbox="allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups">
    </iframe>

</template>

<script>
  import { showLoading, hideLoading } from '@/utils/loading'
  export default {
    name: "editScratchMould",
    data() {
      return {
        scratchIde: process.env.VUE_APP_SCRATCH_IDE,
        filePath:"",
        moudlid:"",
        token:"",
        fileName:"",
        pageType:""
      }
    },
    mounted() {
      console.log(JSON.stringify(this.$route.query))
      showLoading();
      this.pageType = this.$route.query.pageType
      this.filePath = this.$route.query.file
      if(this.$route.query.moudlid != undefined && this.$route.query.moudlid != null) {
        this.moudlid = this.$route.query.moudlid
        this.token = this.$route.query.token
        this.fileName = this.$route.query.fileName
      }

      setTimeout(()=> {
        this.$refs.leftFrame.contentWindow.postMessage({
          pageType:this.pageType,
          filePath:this.filePath,
          moudlid:this.moudlid,
          token:this.token,
          fileName:this.fileName
        }, '*')
      },2500)

      window.addEventListener('message', function(msg) {
        if(msg.data === "pageLoadOver") {
          hideLoading()
        }
      });
    },

    methods: {

    },
  }
</script>

<style scoped>

</style>
